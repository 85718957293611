import { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';
import Section from '../../components/Section';
import SectionAlternate from '../../components/SectionAlternate';
import Hero from './components/Hero';
import Platform from './components/Platform';
import Contact from './components/Contact';
import connectedWorld from './undraw_connected_world_wuay.svg';
import reviewedDocuments from './undraw_Reviewed_docs_re_9lmr.svg';
import onlineConnection from './undraw_online_connection_6778.svg';

const useStyles = makeStyles((theme: any) => ({
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
}));

export const platform = [
  {
    title: 'Your Data From Across The Web',
    description: 'Find, track and organise your data sources and get automatic analytics.',
    illustration: connectedWorld,
  },
  {
    title: 'Multilingual Support',
    description: 'Bypass language barriers and get novel insights.',
    illustration: reviewedDocuments,
  },
  {
    title: 'Correlate Articles Automatically',
    description:
      'Our AI will help you have a deep understanding of how your data sources and their global context.',
    illustration: onlineConnection,
  },
];

function Home() {
  const classes = useStyles();

  return (
    <div>
      <Section>
        <Hero />
      </Section>
      <SectionAlternate>
        <Platform data={platform} />
      </SectionAlternate>
      <Divider />
      <Section fullWidth className={classes.sectionAlternate}>
        <Contact />
      </Section>
    </div>
  );
}

export default memo(Home);

import { memo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from './components/RouteWithLayout';
import MinimalLayout from './layouts/Minimal';
import HomeView from './views/Home';
import NotFoundView from './views/NotFound';

function Routes() {
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
}

export default memo(Routes);

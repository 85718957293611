import { memo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

interface SectionProps {
  /**
   * External classes
   */
  className?: string;
  /**
   * Children to placed inside the section
   */
  children?: JSX.Element;
  /**
   * Should show narrow sections
   */
  narrow?: boolean;
  /**
   * Should the section be full width
   */
  fullWidth?: boolean;
  /**
   * Should the section render with no padding
   */
  disablePadding?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1236,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 8),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 8),
    },
  },
  fullWidth: {
    maxWidth: '100%',
  },
  disablePadding: {
    padding: 0,
  },
  narrow: {
    maxWidth: 800,
  },
  inner: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 8),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 8),
    },
  },
}));

/**
 * Component to display the sections
 *
 * @param {Object} props
 */
function Section({
  children,
  fullWidth,
  narrow,
  disablePadding,
  className,
  ...rest
}: SectionProps) {
  const classes = useStyles();

  return (
    <section
      className={clsx(
        'section',
        classes.root,
        fullWidth ? classes.fullWidth : {},
        narrow ? classes.narrow : {},
        disablePadding ? classes.disablePadding : {},
        className,
      )}
      {...rest}
    >
      <div
        className={clsx(
          'section-alternate__content',
        classes.inner,
        )}
      >
        {children}
      </div>
    </section>
  );
};

export default memo(Section);

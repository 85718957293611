import { memo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContactForm from '../../../../components/ContactForm';
import SectionHeader from '../../../../components/SectionHeader';
import CardBase from '../../../../components/CardBase';

interface Prop {
  className?: string;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: theme.palette.alternate.main,
  },
}));

function Contact({ className }: Prop) {
  const classes = useStyles();

  return (
    <div id="contact-us" className={className} data-aos="fade-up">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SectionHeader
            title="Would you like to use our technology on a project?"
            subtitle="Get in touch beside and we will get back to you soon to connect about your project."
            align="left"
            disableGutter
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardBase withShadow liftUp className={classes.root}>
            <ContactForm />
          </CardBase>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(Contact);

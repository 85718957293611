import { useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import SectionHeader from '../../../../components/SectionHeader';

interface Props {
  className?: string;
}

const Hero = ({ className }: Props): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleContactUsClick = useCallback(() => {
    document.getElementById('contact-us')?.scrollIntoView();
  }, []);

  return (
    <div className={className}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
        <SectionHeader
          title={
            <span>
              Welcome to{' '}
              <Typography component="span" variant="inherit" color="primary">INMTEL</Typography>
              .
              <br />
              <span>Uncover insights across the world.</span>
            </span>
          }
          subtitle="Select from our data feeds and let our AI tools help you find their connections."
          ctaGroup={[
            <Button variant="contained" color="primary" size="large" onClick={handleContactUsClick}>
              Contact us
            </Button>
          ]}
          align="center"
          disableGutter
          titleVariant="h3"
        />
      </Grid>
    </div>
  );
};

export default Hero;

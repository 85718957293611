import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import OnlineProvider from './providers/Online';
import NotificationsProvider from './providers/Notifications';
import Loading from './components/Loading';
import Routes from './Routes';
import theme from './theme';

export default function App() {
  return (
    <Suspense fallback={<Loading />}>
      <NotificationsProvider>
        <OnlineProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Paper elevation={0}>
              <Router>
                <Routes />
              </Router>
            </Paper>
          </ThemeProvider>
        </OnlineProvider>
      </NotificationsProvider>
    </Suspense>
  );
}
